import React, {useState, useEffect, useRef} from 'react';
import {GoogleMap, LoadScript, MarkerF} from '@react-google-maps/api';
import {useLocation} from "react-router-dom";
import './EnrollmentPage.css';
import EMLogo from "./logo.svg"
import axios from "axios";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';


dayjs.extend(relativeTime);

const defaultCenter = {latitude: -33.8688, longitude: 151.2093};

const buttonStyles = {
    display: 'block',
    width: '80%',
    maxWidth: '300px',
    margin: '20px auto',
    padding: '15px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#8942f4',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
};

const modalContentStyles = {
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    width: '80%',
    textAlign: 'center',
    // color: 'red',
    fontWeight: 'bold',
    maxWidth: '600px',
};

const logoStyle = {
    padding: '10px',
    width: '50%',
    maxWidth: '300px',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
};

const logoContainerStyle = {
    display: 'flex',
    justifyContent: 'center',  // Horizontally centers the child content
    alignItems: 'center',      // Vertically centers the child content
    width: '100%',             // Ensure the container takes up the full width
    //position: 'sticky',
    top: 0, 
    // backgroundColor: '#fff', 
    padding: '2px 0',
    zIndex: 1000,
};

const successTickStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '100px',
    color: 'green'
};

const successMessageStyles = {
    marginTop: '20px',
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'black'
};

const labelStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
    marginRight: '5px',
    minWidth: '110px', // Ensure labels are aligned by setting a minWidth
    textAlign: 'left',
};



const InputWrapper = styled.div`
  display: flex;       // Set this as a flex container
  align-items: center; // Center align items vertically
  margin-bottom: 10px;
`;

const Label = styled.label`
  // Add styles for the label here, for instance:
  font-weight: bold;
  flex-shrink: 0;      // Ensure the label doesn't shrink
  margin-right: 10px;  // Space between the label and the input
`;

const Input = styled.input`
  padding: 5px;
  flex-grow: 1;        // Let the input take up remaining space
  border: 1px solid #d0d0d0;  // Light gray border
  border-radius: 6px;  // Rounded borders
  max-width: 160px;     // Limit the width of the input
  font-size: 16px;

  @media (min-width: 768px) {
    max-width: 200px;   // Increase the max-width for larger screens
  }

  &:active, &:focus {
    border-color: rgb(137, 66, 244);  // Purple border when active/focused
    outline: none;  // Remove the default browser outline
    box-shadow: 0 0 5px rgba(137, 66, 244, 0.5);  // Optional: add a soft purple glow when active/focused
  }
`;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const extractAddress = (addressComponents) => {
    let extracted = {
        postal_code: null,
        country: null,
        administrative_area_level_1: null,
        administrative_area_level_2: null,
        locality: null,
        route: null,
        street_number: null
    };

    for (let component of addressComponents) {
        if (component.types.includes("postal_code")) {
            extracted.postal_code = component.long_name;
        }
        if (component.types.includes("country")) {
            extracted.country = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
            extracted.administrative_area_level_1 = component.long_name;
        }
        if (component.types.includes("administrative_area_level_2")) {
            extracted.administrative_area_level_2 = component.long_name;
        }
        if (component.types.includes("locality")) {
            extracted.locality = component.long_name;
        }
        if (component.types.includes("route")) {
            extracted.route = component.long_name;
        }
        if (component.types.includes("street_number")) {
            extracted.street_number = component.long_name;
        }
    }

    return extracted;
};

const enrollDevice = async (deviceData) => {
    try {
        const response = await axios.post('/enroll', deviceData);
        return response.data;
    } catch (error) {
        console.error("Failed to enroll device:", error);
        if (error.response) {
            return error.response.data;
        }
        return null;
    }
};

const reverseGeocode = async (latitude, longitude) => {
    const apiKey = "AIzaSyD-L5HxnsKbZpMbMf1V1kQBqpYo7r7z3oc";
    try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
        const data = response.data;
        if (data && data.results && data.results.length > 0) {
            return extractAddress(data.results[0].address_components);
        }
    } catch (error) {
        console.error("Failed to fetch reverse geocode:", error);
    }
    return null;
};


function EnrollmentPage() {
    const query = useQuery();
    const serialNumber = query.get("s");
    const [location, setLocation] = useState(defaultCenter);
    const [address, setAddress] = useState(null);
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [deviceStatus, setDeviceStatus] = useState('Unknown'); // Possible values: 'Offline', 'Error', 'Online'
    const [deviceLastMessageDateTime, setDeviceLastMessageDateTime] = useState(null); 
    const [deviceType, setDeviceType] = useState('Unknown'); 

    //Location Details
    const [luminaireIdentifier, setLuminaireIdentifier] = useState("");
    const [hasParkingBays, setHasParkingBays] = useState(false);
    const [hasDisabledParking, setHasDisabledParking] = useState(false);
    const [laneCount, setLaneCount] = useState(1);


    useEffect(() => {
        // Reset Scroll to the top of the page
        window.scrollTo(0, 0);

        // Manual Zoom Reset
        document.body.style.zoom = 1.0;

        (async () => {
            const address = await reverseGeocode(defaultCenter.latitude, defaultCenter.longitude);
            setAddress(address);
        })();

        navigator.geolocation.getCurrentPosition(async position => {
            const {latitude, longitude} = position.coords;
            setLocation({latitude: latitude, longitude: longitude});
            setMapCenter({latitude: latitude, longitude: longitude});
            const address = await reverseGeocode(latitude, longitude);
            setAddress(address);
        }, err => {
            console.error(err);
        });

        fetchDeviceStatus();
        const interval = setInterval(fetchDeviceStatus, 10000);  // Polling every 10 seconds
        return () => clearInterval(interval);

    }, []);

    const handleDragEnd = async (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setLocation({latitude: newLat, longitude: newLng});
        setMapCenter(undefined);

        const address = await reverseGeocode(newLat, newLng);
        setAddress(address);
    };

    // TODO: The previous invocation may still be running by the time the next event is fired, thus the address may be
    //  wrong
    const handleMapClick = async (event) => {
        const clickedLat = event.latLng.lat();
        const clickedLng = event.latLng.lng();
        setLocation({latitude: clickedLat, longitude: clickedLng});
        setMapCenter(undefined);

        const address = await reverseGeocode(clickedLat, clickedLng);
        setAddress(address);
    }

    const handleEnrollClicked = async (event) => {
        setIsLoading(true);
        const response = await enrollDevice({
            serialNumber: serialNumber,
            location: {
                latitude: location.latitude,
                longitude: location.longitude,
                address: address
            },
            metaLuminaireId: luminaireIdentifier,
        });

        setIsLoading(false);
        if (response && response.success) { // Assuming your response has a 'success' field.
            setIsEnrolled(true);
        } else {
            setModalMessage(response && response.error ? response.error : "Enrollment failed. Please try again or contact support.");
        }
    }


    const initialLoad = useRef(true);

    const fetchDeviceStatus = () => {
        axios.get(`/status?serial_number=${serialNumber}`)  // Replace with your serial number or variable
        .then(response => {
            const data = response.data;
            const currentTime = new Date();
            const lastMessageTime = new Date(data.lastMessageTime);
            const timeDifferenceHours = (currentTime - lastMessageTime) / (1000 * 60 * 60);  // Difference in hours

            let status;

            if (data.healthy) {
                status = "Online";
            } else if (!data.healthy && timeDifferenceHours <= 24) {
                status = "Online (unhealthy)";
            } else {
                status = "Offline";
            }

            setDeviceType(data.deviceType === 'vision' ? 'Vision + IR' : 'enviornment' ? 'Environment + IR' : 'Unknown');
            setDeviceStatus(status); 
            setDeviceLastMessageDateTime(data.lastMessageTime);
            if (initialLoad.current) {
                setLuminaireIdentifier(data.metaLuminaireId);
                initialLoad.current = false;  // set the flag to false after the initial load
            }
        })
        .catch(error => {
            console.log("Error fetching device status", error);
            if (error.response && error.response.status === 404) {
                setModalMessage("Error, device serial number not found. Please try again or contact support.");
            }
        });
    };



    const headingStyle = window.innerWidth <= 768 ? {fontSize: '18px', textAlign: 'center'} : {fontSize: '24px', margin: '10px'};
    const containerStyle = {width: '100%', height: 0.70 * window.innerHeight, margin: '0 1px'};
    const spinnerStyles = {
        display: isLoading ? 'block' : 'none',
        margin: '0 auto',
        border: '16px solid #707070',
        overflow: 'show',
        borderRadius: '50%',
        borderTop: '16px solid #8942f4',
        width: '100px',
        height: '100px',
        animation: 'spin 2s linear infinite',
        position: 'fixed',
        top: '50%',
        left: '0',
        bottom: '0', 
        right: '0',
        zIndex: '999'
    };

    const modalStyles = {
        display: modalMessage ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)' // Semi-transparent black
    };

    

    const StatusDisplay = () => {
        const statusColor = deviceStatus === 'Online' ? '62,255,122' : (deviceStatus === 'Error' ? '255,165,0' : (deviceStatus === 'Offline' ? '255, 62, 102' : '128,128,128'));
        const statusColorRgb = 'rgb(' + statusColor + ')';

        const dotStyles = {
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '5px',
        };
        
        const textStyle = {
            fontSize: '16px',
            color: '#333',
        };
    
        const headingStyle = {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '5px',
            color: '#333',
            textAlign: 'cente',
            marginRight: '5px',
            margin: '10px',
        };
    
        const valueStyle = {
            fontSize: '16px',
            color: '#555',
        };
        
        return ( 
            <div>
                {/* <h1 style={headingStyle}>Device Details</h1> */}
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>
                        <span style={labelStyle}>Device type:</span>
                        <span style={valueStyle}>{deviceType}</span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>
                        <span style={labelStyle}>Serial:</span>
                        <span style={valueStyle}>{serialNumber}</span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>
                        <span style={labelStyle}>Status:</span>
                        <span style={{...valueStyle, display: 'flex', alignItems: 'center'}}>
                            <span style={{...dotStyles, backgroundColor: statusColorRgb, '--dynamic-color': statusColor}} className={'pulse'}></span> {deviceStatus}
                        </span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={labelStyle}>Last activity:</span>
                        <span style={valueStyle}><i>{dayjs().diff(deviceLastMessageDateTime, 'hour') < 24 ? 
                                                        dayjs(deviceLastMessageDateTime).fromNow() : 'No messages received in the last 24 hours.'}</i></span>
                    </div>
                </div>
            </div>
            
        );
    };

    if (isEnrolled) {
        return (
            <>
                <div style={successTickStyles}>
                    <div style={{fontSize: '100px', color: 'rgba(22, 222, 82, 0.9)', marginBottom: '15px', margin: '2px'}}>✔</div>
                    <div style={successMessageStyles}>Device Enrolled Successfully</div>
                    <p style={{fontSize: '16px', color: 'black'}}>You can now close this tab.</p>
                </div>
                
            </>
        ) 
    }

    return (
        <>
            <div style={logoContainerStyle} >
                <img src={EMLogo} alt="Edgemachines" style={logoStyle} />
            </div> 
            
            <div className="card-container">
                {/* Info Section */}
                <div className="card">
                    <div className="card-header">Device Enrolment and Configuration</div>
                    {/* <h1 style={headingStyle}>Device Enrolment and Configuration</h1> */}
                    <div className="card-body">
                        <p>
                            Below you can view the current status of an EdgeMachines Zhaga Sensor 
                            and setup key metadata for the device such as location.
                            Location access is required to help identify the device location.
                        </p>
                    </div>
                </div>
                 
                {/* Device Details Section */}
                <div className="card">
                    <div className="card-header">Device Details</div>
                    <div className="card-body">
                        <StatusDisplay />
                    </div>
                </div>
                
                {/* Location Section */}
                <div className="card">
                    <div className="card-header">Device Location</div>

                    <div style={{marginBottom: '20px'}}>
                        <InputWrapper>
                            <Label htmlFor="luminaireIdentifier">Luminaire Identifier:</Label>
                            <Input
                                type="text"
                                id="luminaireIdentifier"
                                value={luminaireIdentifier}
                                onChange={e => setLuminaireIdentifier(e.target.value)}
                                maxLength={20}  // Limit input to 20 characters
                            />
                        </InputWrapper>
                    </div>
                    {/* <h1 style={headingStyle}>Please select the location of the device</h1> */}
                    <LoadScript
                        googleMapsApiKey="AIzaSyD-L5HxnsKbZpMbMf1V1kQBqpYo7r7z3oc"
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={mapCenter ? {lat: mapCenter.latitude, lng: mapCenter.longitude} : null}
                            zoom={18}
                            options={{
                                streetViewControl: false,
                                clickableIcons: false,
                                fullscreenControl: false,
                                mapTypeControl: false
                            }}
                            onClick={handleMapClick}
                        >
                            {/* Temporary Marker (Crosshair) */}
                            {!location && (
                                <MarkerF
                                    position={{lat: location.latitude, lng: location.longitude}}
                                    icon={{
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        scale: 10,
                                        strokeColor: '#FF0000'
                                    }}
                                />
                            )}

                            {/* Actual Marker */}
                            {location && (
                                <MarkerF
                                    position={{lat: location.latitude, lng: location.longitude}}
                                    draggable={true}
                                    onDragEnd={handleDragEnd}
                                />
                            )}
                        </GoogleMap>
                    </LoadScript>
                </div>
                
                {/* <div className="card">
                    <div className="card-header">Location Details</div>
                    <div className="card-body">
                        

                        {deviceType === 'Vision + IR Magic' && (
                            <>
                                <div style={{marginBottom: '10px'}}>
                                    <input
                                        type="checkbox"
                                        id="hasParkingBays"
                                        checked={hasParkingBays}
                                        onChange={e => setHasParkingBays(e.target.checked)}
                                    />
                                    <label htmlFor="hasParkingBays" style={{marginLeft: '10px'}}>Are there Parking Bays at this location?</label>
                                </div>

                                <div style={{marginBottom: '10px'}}>
                                    <input
                                        type="checkbox"
                                        id="hasDisabledParking"
                                        checked={hasDisabledParking}
                                        onChange={e => setHasDisabledParking(e.target.checked)}
                                    />
                                    <label htmlFor="hasDisabledParking" style={{marginLeft: '10px'}}>Is there Accessible/Disabled Parking at this location?</label>
                                </div>
                                <div>
                                    <label htmlFor="laneCount">How many lanes does the roadway have (including lanes for parking)?</label>
                                    <input
                                        type="number"
                                        id="laneCount"
                                        value={laneCount}
                                        onChange={e => setLaneCount(Math.max(1, e.target.value))}  // Ensure it's at least 1
                                        style={{marginLeft: '10px', width: '50px', padding: '5px'}}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div> */}

            </div>                
            <button style={buttonStyles} onClick={handleEnrollClicked}>
                Enroll Device
            </button>

            <div style={spinnerStyles}></div>

            <div style={modalStyles} onClick={() => setModalMessage(null)}>
                <div style={modalContentStyles} onClick={e => e.stopPropagation()}> {/* This stops the modal from closing if the inner content is clicked */}
                    <div style={{fontSize: '100px', color: 'rgba(255, 62, 102, 0.9)', marginBottom: '15px', margin: '2px'}}>⚠</div>
                    {modalMessage}
                </div>
            </div>
        </>
    );
}

export default EnrollmentPage;