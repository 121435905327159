import React, {useState} from 'react';
import EMLogo from "./logo.svg"


const DocumentsPage = () => {
    return (
        <>
            <div>
                <img src={EMLogo} alt="Edgemachines" className='app-logo'/>
            </div>
            <div className="button-container">
                <a href="/quickstart?t=visionir" target="_blank" rel="noopener noreferrer" className='pdf-button'>Vision
                    Sensor Quickstart Guide</a>
                <br/>
                <a href="/quickstart?t=envir" target="_blank" rel="noopener noreferrer" className='pdf-button'>Environment
                    Sensor Quickstart Guide</a>
                <style>
                    {`
                      .button-container {
                        display: flex;
                        flex-direction: column;
                        align-items: left;
                      }
                      .pdf-button {
                        display: block;
                        width: 80%;
                        max-width: 300px;
                        margin: 10px 10px;
                        padding: 15px;
                        font-size: 16px;
                        font-weight: bold;
                        background-color: #8942f4;
                        color: white;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        text-align: center;
                        text-decoration: none; 
                      }
                      .pdf-button:hover {
                        background-color: #6a31d1;
                      }
                      .app-logo {
                        padding: 10px;
                        width: 50%;
                        max-width: 300px;
                        height: auto;
                      }
                    `}
                </style>
            </div>
        </>
    );
};

export default DocumentsPage;