import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import './RootPage.css';
import EMLogo from "./logo.svg"

const logoStyle = {
    padding: '10px',
    width: '50%',
    maxWidth: '300px',
    height: 'auto',
};

const headingStyle = window.innerWidth <= 768 ? {fontSize: '18px', margin: '10px', textAlign: 'center'} : {fontSize: '24px', margin: '20px', textAlign: 'center'};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function RootPage() {
    
    return (
        <>
            <div style={{textAlign: 'center'}}>
                <img src={EMLogo} alt="Edgemachines" style={logoStyle} />
                <br/>
                <h1 style={headingStyle}>Please scan the QR Code on the device to get started.</h1>
                <p style={{fontSize: '16px', margin: '6px'}}>The QR Code can be found on the white sticker on the outside of the canister, just below the black Zhaga cap.</p>
            </div>
            
        </>
    );
}

export default RootPage;