import EnrollmentPage from "./EnrollmentPage";
import RootPage from "./RootPage";
import LoginPage from './LoginPage';
import {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import axios from "axios";
import DocumentsPage from "./DocumentsPage";

function Footer() {
    return (
        <footer className="footer">
            Copyright © {new Date().getFullYear()} <strong>EDGEMACHINES PTY LTD</strong> ABN 99 667 413 998. All rights
            reserved.
        </footer>
    );
}

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const sessionUser = sessionStorage.getItem('user');
        if (sessionUser) {
            setIsLoggedIn(true);
        }
    }, []);

    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        // TODO: this is too broad as it captures 401s from other services too
        if (error.response.status === 401) {
            setIsLoggedIn(false);
        }
        return Promise.reject(error);
    });

    const handleLogin = () => {
        sessionStorage.setItem('user', 'logged-in');
        setIsLoggedIn(true);
    };

    if (!isLoggedIn) {
        return <LoginPage onLogin={handleLogin}/>;
    }

    return (
        <Router>
            <div className="app-container">
                <div className="content">
                    <Routes>
                        <Route path="/documents" element={<DocumentsPage/>}/>
                        <Route path="/r" element={<EnrollmentPage/>}/>
                        <Route path="/" element={<RootPage/>}/>
                        <Route path="/login" element={<LoginPage onLogin={handleLogin}/>}/>
                    </Routes>
                </div>
                <Footer/>
            </div>
            <style>
                {`
                    .app-container {
                        display: flex;
                        flex-direction: column;
                        min-height: 100vh;
                        height: 100%
                    }
                    
                    .content {
                        flex: 1;
                    }
                    
                    .footer {
                        width: 100%;
                        padding: 20px 0;
                        text-align: center;
                        background-color: #f7f7f7;
                        border-top: 1px solid #e5e5e5;
                        font-size: 14px;
                    }
                `}
            </style>
        </Router>
    );
}

export default App;
