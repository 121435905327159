import React, { useState } from 'react';
import EMLogo from "./logo.svg"

const loginContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // occupy full viewport height
    padding: '20px',
    fontFamily: 'Arial, sans-serif'
};

const inputStyles = {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    display: 'block'
};

const buttonStyles = {
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
    maxWidth: '300px',
    margin: '20px 0',
    padding: '15px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#8942f4',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
};

const errorStyles = {
    color: 'red',
    margin: '10px 0',
    textAlign: 'center',
    width: '100%',
    maxWidth: '300px',
    fontSize: '16px',
};

const logoStyle = {
    width: '100%',
    maxWidth: '400px',
    maxHeight: '50',
    height: 'auto',
    display: 'block',
    margin: '20px auto'
};

const LoginPage = ({ onLogin }) => {
    const [installerCode, setInstallerCode] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (installerCode) {
            try {
                const response = await fetch('/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        installerCode: installerCode
                    })
                });

                const data = await response.json();

                if (data.success) {
                    onLogin();
                } else {
                    setError(data.message || "Invalid installer Code.");
                }
            } catch (error) {
                setError("An error occurred. Please try again later.");
            }
        } else {
            setError("Please provide an installer code.");
        }
    };

    return (
        <div style={loginContainerStyles}>
            <img src={EMLogo} alt="Edgemachines" style={logoStyle} />

            {error && <div style={errorStyles}>{error}</div>}

            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Installer Code"
                    value={installerCode}
                    onChange={(e) => setInstallerCode(e.target.value)}
                    style={inputStyles}
                />
                <button type="submit" style={buttonStyles}>Login</button>
            </form>
        </div>
    );
};

export default LoginPage;